import { useState, useRef, forwardRef, useImperativeHandle } from "react";

import classes from "./Birb2.module.css";

const Birb2 = forwardRef((props, ref) => {
  const eyeRef = useRef();
  const [eyeDeg, setEyeDeg] = useState(0);

  const { x: mouseX, y: mouseY } = props.mouseCoords;

  const angle = (cx, cy, ex, ey) => {
    const dy = ey - cy;
    const dx = ex - cx;
    const rad = Math.atan2(dy, dx);
    const deg = (rad * 180) / Math.PI;
    return deg;
  };

  useImperativeHandle(ref, () => ({
    angleCalc() {
      const rekt = eyeRef.current.getBoundingClientRect();
      const anchorX = rekt.left + rekt.width / 2;
      const anchorY = rekt.top + rekt.height / 2;
      const angleDeg = angle(mouseX, mouseY, anchorX, anchorY);

      setEyeDeg(180 - angleDeg);
    },
  }));

  return (
    <div className={classes["birb-container"]}>
      <svg
        width="150"
        height="150"
        viewBox="0 0 150 150"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="birb2">
          <g id="beak-closed">
            <path
              d="M31.4375 48.8845C29.5265 47.9286 25.1937 47.9231 21.1349 47.9231C17.1101 47.9231 24.7055 53.0088 29.6633 55.5514C29.7278 55.829 29.7887 55.6154 29.7887 55.6154L29.5144 51.7691L31.4375 48.8845Z"
              fill="#BB7E5D"
            />
            <path
              d="M21.1349 47.9231C25.1937 47.9231 29.5265 47.9286 31.4375 48.8845L29.5144 51.7691M21.1349 47.9231C17.076 47.9231 24.8349 53.0953 29.7887 55.6154M21.1349 47.9231C23.0579 48.8846 27.5913 50.8076 29.5144 51.7691M29.7887 55.6154C29.7887 55.6154 29.5144 56.5768 29.5144 51.7691M29.7887 55.6154L29.5144 51.7691"
              stroke="black"
              strokeWidth="1.64193"
            />
          </g>
          <g id="body">
            <path
              d="M61.245 59.4615L59.322 50.8077C59.322 47.5663 48.7268 42.1538 43.9374 42.1538C39.5886 42.1538 32.3989 48.8846 30.0398 53.4137C29.5143 54.6538 30.0398 57.0152 30.0398 57.0152C30.8179 59.5929 32.1307 62.3391 33.1644 64.5014L33.1644 64.5015C33.575 65.3603 33.9416 66.1271 34.2131 66.7545L32.8735 75.7431C33.2307 79.3061 37.7336 93.7518 40.0912 97.9231C41.6938 100.487 47.5972 105.577 60.66 106.557C73.745 107.538 83.9455 105.999 87.2066 105.615C87.8126 105.349 88.4619 105.039 89.1297 104.687C93.0762 102.608 97.6699 99.0749 97.8074 94.527C103.505 95.6563 112.261 96.0243 115.927 96.0671C115.302 94.6767 113.248 90.3838 110.033 84.3354C108.315 81.1026 105.091 77.7529 101.794 74.7776C102.023 74.5483 102.283 74.3049 102.559 74.0458L102.559 74.0454C104.317 72.3951 106.755 70.1067 106.505 66.7545C106.215 62.8757 108.679 60.2566 108.679 60.2566L116.053 41.1923L110.491 43.6896L111.578 28.7668L106.867 33.9655L108.36 21C102.774 28.7427 94.9373 44.6965 93.958 48.0114C93.6157 49.1701 92.8023 51.1717 91.8528 53.508L91.8528 53.508C90.0859 57.8556 87.848 63.3624 87.298 66.7545C84.4145 66.3248 82.0808 66.1184 80.5487 65.9828L80.5482 65.9828C79.8405 65.9202 79.3037 65.8727 78.9629 65.8254C78.0427 65.6974 76.9236 65.7029 71.822 63.3077C68.6056 61.7976 63.8004 60.5851 61.245 59.4615Z"
              fill="#BB7E5D"
            />
            <path
              d="M97.8074 94.527C97.6699 99.0749 93.0762 102.608 89.1297 104.687C88.4619 105.039 87.8126 105.349 87.2066 105.615C83.9455 105.999 73.745 107.538 60.66 106.557C47.5972 105.577 41.6938 100.487 40.0912 97.9231C37.7336 93.7518 33.2307 79.3061 32.8735 75.7431L34.213 66.7545C33.9416 66.1271 33.575 65.3603 33.1644 64.5015C32.1308 62.3392 30.8179 59.5929 30.0398 57.0152C30.0398 57.0152 29.5143 54.6538 30.0398 53.4137C32.3989 48.8846 39.5886 42.1538 43.9374 42.1538C48.7268 42.1538 59.322 47.5663 59.322 50.8077L61.245 59.4615C63.8004 60.5851 68.6056 61.7976 71.822 63.3077C76.9236 65.7029 78.0427 65.6974 78.9629 65.8254C79.3038 65.8727 79.8407 65.9202 80.5487 65.9828C82.0808 66.1184 84.4145 66.3248 87.298 66.7545M97.8074 94.527C103.505 95.6563 112.261 96.0243 115.927 96.0671C115.302 94.6767 113.248 90.3838 110.033 84.3354M97.8074 94.527C86.902 92.8986 64.8989 87.6443 64.1297 79.6538M110.033 84.3354C108.315 81.1026 105.091 77.7529 101.794 74.7776M110.033 84.3354C107.218 79.0393 100.363 73.4296 95.7633 69.6658M101.794 74.7776C102.023 74.5482 102.283 74.3047 102.559 74.0454C104.317 72.3951 106.755 70.1067 106.505 66.7545C106.215 62.8757 108.679 60.2566 108.679 60.2566L116.053 41.1923L110.491 43.6896L111.578 28.7668L106.867 33.9655L108.36 21C102.774 28.7427 94.9373 44.6965 93.958 48.0114C93.6157 49.1701 92.8023 51.1717 91.8528 53.508C90.0859 57.8556 87.848 63.3624 87.298 66.7545M101.794 74.7776C99.6894 72.8786 97.5552 71.1321 95.7633 69.6658M87.298 66.7545C88.0646 66.8687 89.4015 67.1904 90.6855 67.4993C92.162 67.8546 93.5687 68.193 93.958 68.1799C94.5022 68.6339 95.11 69.1312 95.7633 69.6658"
              stroke="black"
              strokeWidth="1.64193"
            />
          </g>
          <circle
            id="eye"
            r="5.91806"
            transform="matrix(-1 0 0 1 45.7043 52.7043)"
            fill="white"
            stroke="black"
            strokeWidth="1.57258"
            ref={eyeRef}
          />
          <path
            id="left-foot"
            d="M89.1297 104.654L91.0527 109.462L83.4609 116.393M83.4609 116.393L84.322 118.115L86.245 121L83.3604 118.115L79.5143 120.038V118.115L72.7835 121L83.4609 116.393Z"
            stroke="black"
            strokeWidth="1.64193"
          />
          <path
            id="right-foot"
            d="M75.6685 106.577L79.5146 111.385L69.8993 116.192M69.8993 116.192V118.115V119.077L68.9377 118.115L65.0916 119.077V118.115L61.2454 119.077L69.8993 116.192Z"
            stroke="black"
            strokeWidth="1.64193"
          />
          <path
            id="eyeball"
            d="M42 49.25C42 51.0449 43.4551 52.5 45.25 52.5C47.0449 52.5 48.5 51.0449 48.5 49.25C48.5 47.4551 47.0449 46 45.25 46C43.4551 46 42 47.4551 42 49.25Z"
            fill="black"
            className={classes["eyeball-fake"]}
          />
          <g id="hardhat">
            <path
              id="Vector 163"
              d="M31.3202 40.4158C31.6276 39.9885 35.456 39.6999 38.1436 39.8616C38.201 41.2025 38.7358 43.9096 40.4156 44.0107C39.0938 44.9343 37.2787 45.5434 34.3343 45.3663C32.8141 45.2749 30.992 41.1763 31.3202 40.4158Z"
              fill="#EEDE4E"
              stroke="black"
              strokeWidth="1.62779"
            />
            <path
              id="Vector 164"
              d="M41.1143 35.3891C37.4212 38.1427 36.6098 40.4084 36.7667 40.5832L38.6194 43.1745C44.5139 45.5129 54.6072 47.7733 58.9323 48.3641C59.6176 45.4294 60.5726 43.007 60.0152 39.0058C59.4578 35.0045 55.14 33.7528 52.3293 33.0878C49.007 32.5573 45.5666 32.0694 41.1143 35.3891Z"
              fill="#EEDE4E"
              stroke="black"
              strokeWidth="1.62779"
            />
            <path
              id="Vector 165"
              d="M40.9014 43.8812C41.5614 43.4122 42.8816 42.099 42.8816 40.5983C42.8816 39.5519 43.3436 38.7974 43.924 38.2533M59.7132 42.4742L58.7231 41.0673C58.2281 40.4419 56.5449 38.9099 53.7727 37.7843C50.3073 36.3773 46.3469 36.8463 45.3568 37.3153C44.9189 37.5227 44.3842 37.8219 43.924 38.2533M42.3865 34.9703C41.9264 36.221 41.5896 38.6285 43.924 38.2533"
              stroke="black"
              strokeWidth="1.62779"
            />
          </g>
          <path
            id="Vector 199"
            d="M51.5 83.375C48.7 77.2133 38.5 78.5 34 79.5C36 86.3998 40 98.5 42.5 100.5C46.5 103.7 54.5 106 58 106L56.5 99.742C56 96.8537 54.3 89.5367 51.5 83.375Z"
            fill="#F5EFEF"
            stroke="black"
            strokeWidth="1.62779"
          />
        </g>
      </svg>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="6.6"
        height="6.6"
        viewBox="0 0 4 4"
        fill="none"
        className={classes["eyeball-real"]}
        style={{
          transform: `rotate(${90 - eyeDeg}deg)`,
        }}
      >
        <path
          id="eyeballz"
          d="M4 2C4 3.10457 3.10457 4 2 4C0.89543 4 0 3.10457 0 2C0 0.89543 0.89543 0 2 0C3.10457 0 4 0.89543 4 2Z"
          fill="black"
        />
      </svg>
    </div>
  );
});

export default Birb2;
