import { useState, useRef, forwardRef, useImperativeHandle } from "react";

import classes from "./Birb3.module.css";

const Birb3 = forwardRef((props, ref) => {
  const eyeRef = useRef();
  const [eyeDeg, setEyeDeg] = useState(0);

  const { x: mouseX, y: mouseY } = props.mouseCoords;

  const angle = (cx, cy, ex, ey) => {
    const dy = ey - cy;
    const dx = ex - cx;
    const rad = Math.atan2(dy, dx);
    const deg = (rad * 180) / Math.PI;
    return deg;
  };

  useImperativeHandle(ref, () => ({
    angleCalc() {
      const rekt = eyeRef.current.getBoundingClientRect();
      const anchorX = rekt.left + rekt.width / 2;
      const anchorY = rekt.top + rekt.height / 2;
      const angleDeg = angle(mouseX, mouseY, anchorX, anchorY);

      setEyeDeg(180 - angleDeg);
    },
  }));

  return (
    <div className={classes["birb-container"]}>
      <svg
        width="150"
        height="150"
        viewBox="0 0 150 150"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="birb3">
          <g id="beak-closed">
            <path
              d="M38.4375 52.8845C36.5265 51.9286 32.1937 51.9231 28.1349 51.9231C24.1101 51.9231 31.7055 57.0088 36.6633 59.5514C36.7278 59.829 36.7887 59.6154 36.7887 59.6154L36.5144 55.7691L38.4375 52.8845Z"
              fill="#BB7E5D"
            />
            <path
              d="M28.1349 51.9231C32.1937 51.9231 36.5265 51.9286 38.4375 52.8845L36.5144 55.7691M28.1349 51.9231C24.076 51.9231 31.8349 57.0953 36.7887 59.6154M28.1349 51.9231C30.0579 52.8846 34.5913 54.8076 36.5144 55.7691M36.7887 59.6154C36.7887 59.6154 36.5144 60.5768 36.5144 55.7691M36.7887 59.6154L36.5144 55.7691"
              stroke="black"
              strokeWidth="1.64193"
            />
          </g>
          <g id="body">
            <path
              d="M68.245 63.4615L66.322 54.8077C66.322 51.5663 55.7268 46.1538 50.9374 46.1538C46.5886 46.1538 39.3989 52.8846 37.0398 57.4137C36.5143 58.6538 37.0398 61.0152 37.0398 61.0152C37.8179 63.5929 39.1307 66.3391 40.1644 68.5014L40.1644 68.5015C40.575 69.3603 40.9416 70.1271 41.2131 70.7545L39.8735 79.7431C40.2307 83.3061 44.7336 97.7518 47.0912 101.923C48.6938 104.487 54.5972 109.577 67.66 110.557C80.745 111.538 90.9455 109.999 94.2066 109.615C94.8126 109.349 95.4619 109.039 96.1297 108.687C100.076 106.608 104.67 103.075 104.807 98.527C110.505 99.6563 119.261 100.024 122.927 100.067C122.302 98.6767 120.248 94.3838 117.033 88.3354C115.315 85.1026 112.091 81.7529 108.794 78.7776C109.023 78.5483 109.283 78.3049 109.559 78.0458L109.559 78.0454C111.317 76.3951 113.755 74.1067 113.505 70.7545C113.215 66.8757 115.679 64.2566 115.679 64.2566L123.053 45.1923L117.491 47.6896L118.578 32.7668L113.867 37.9655L115.36 25C109.774 32.7427 101.937 48.6965 100.958 52.0114C100.616 53.1701 99.8023 55.1717 98.8528 57.508L98.8528 57.508C97.0859 61.8556 94.848 67.3624 94.298 70.7545C91.4145 70.3248 89.0808 70.1184 87.5487 69.9828L87.5482 69.9828C86.8405 69.9202 86.3037 69.8727 85.9629 69.8254C85.0427 69.6974 83.9236 69.7029 78.822 67.3077C75.6056 65.7976 70.8004 64.5851 68.245 63.4615Z"
              fill="#36382E"
            />
            <path
              d="M104.807 98.527C104.67 103.075 100.076 106.608 96.1297 108.687C95.4619 109.039 94.8126 109.349 94.2066 109.615C90.9455 109.999 80.745 111.538 67.66 110.557C54.5972 109.577 48.6938 104.487 47.0912 101.923C44.7336 97.7518 40.2307 83.3061 39.8735 79.7431L41.213 70.7545C40.9416 70.1271 40.575 69.3603 40.1644 68.5015C39.1308 66.3392 37.8179 63.5929 37.0398 61.0152C37.0398 61.0152 36.5143 58.6538 37.0398 57.4137C39.3989 52.8846 46.5886 46.1538 50.9374 46.1538C55.7268 46.1538 66.322 51.5663 66.322 54.8077L68.245 63.4615C70.8004 64.5851 75.6056 65.7976 78.822 67.3077C83.9236 69.7029 85.0427 69.6974 85.9629 69.8254C86.3038 69.8727 86.8407 69.9202 87.5487 69.9828C89.0808 70.1184 91.4145 70.3248 94.298 70.7545M104.807 98.527C110.505 99.6563 119.261 100.024 122.927 100.067C122.302 98.6767 120.248 94.3838 117.033 88.3354M104.807 98.527C93.902 96.8986 71.8989 91.6443 71.1297 83.6538M117.033 88.3354C115.315 85.1026 112.091 81.7529 108.794 78.7776M117.033 88.3354C114.218 83.0393 107.363 77.4296 102.763 73.6658M108.794 78.7776C109.023 78.5482 109.283 78.3047 109.559 78.0454C111.317 76.3951 113.755 74.1067 113.505 70.7545C113.215 66.8757 115.679 64.2566 115.679 64.2566L123.053 45.1923L117.491 47.6896L118.578 32.7668L113.867 37.9655L115.36 25C109.774 32.7427 101.937 48.6965 100.958 52.0114C100.616 53.1701 99.8023 55.1717 98.8528 57.508C97.0859 61.8556 94.848 67.3624 94.298 70.7545M108.794 78.7776C106.689 76.8786 104.555 75.1321 102.763 73.6658M94.298 70.7545C95.0646 70.8687 96.4015 71.1904 97.6855 71.4993C99.162 71.8546 100.569 72.193 100.958 72.1799C101.502 72.6339 102.11 73.1312 102.763 73.6658"
              stroke="black"
              strokeWidth="1.64193"
            />
          </g>
          <circle
            id="eye"
            r="5.87903"
            transform="matrix(-1 0 0 1 52.8913 56.6999)"
            fill="white"
            stroke="black"
            strokeWidth="1.64193"
            ref={eyeRef}
          />
          <path
            id="left-foot"
            d="M96.1297 108.654L98.0527 113.462L90.4609 120.393M90.4609 120.393L91.322 122.115L93.245 125L90.3604 122.115L86.5143 124.038V122.115L79.7835 125L90.4609 120.393Z"
            stroke="black"
            strokeWidth="1.64193"
          />
          <path
            id="right-foot"
            d="M82.6685 110.577L86.5146 115.385L76.8993 120.192M76.8993 120.192V122.115V123.077L75.9377 122.115L72.0916 123.077V122.115L68.2454 123.077L76.8993 120.192Z"
            stroke="black"
            strokeWidth="1.64193"
          />
          <path
            id="eyeball"
            d="M49 54.5C49 56.433 50.567 58 52.5 58C54.433 58 56 56.433 56 54.5C56 52.567 54.433 51 52.5 51C50.567 51 49 52.567 49 54.5Z"
            fill="black"
            className={classes["eyeball-fake"]}
          />
          <path
            id="Vector 199"
            d="M58.5 87.375C55.7 81.2133 45.5 82.5 41 83.5C43 90.3998 47 102.5 49.5 104.5C53.5 107.7 61.5 110 65 110L63.5 103.742C63 100.854 61.3 93.5367 58.5 87.375Z"
            fill="#F5EFEF"
            stroke="black"
            strokeWidth="1.62779"
          />
          <g id="tophat">
            <g id="Vector 193">
              <path
                d="M43.5968 41.787C44.0345 41.5584 46.7436 43.2108 50.8482 43.8966H52.8103C55.7533 36.0916 56.2548 34.641 57.7154 31.1865C58.8843 28.4218 62.0933 31.0837 64.572 33.1398L64.5826 33.1485C66.625 33.6057 71.0855 35.3335 70.4687 37.0726C69.2585 40.485 70.2574 37.4727 68.5067 42.9588C67.5813 45.8587 65.4354 47.5986 64.5826 49.8683L71.5223 54.0892C73.9733 56.8322 70.8633 56.6919 69.2585 56.6919C61.6721 53.0346 46.4119 46.1759 44.3111 44.5301C42.2102 42.8842 43.1591 42.4728 43.5968 41.787Z"
                fill="black"
              />
              <path
                d="M49.5631 46.281C49.6603 45.7292 52.6963 44.5309 52.8103 43.8966M65.5636 54.0892C65.8111 53.4688 64.1447 51.0338 64.5826 49.8683M64.5826 49.8683C65.4354 47.5986 67.5813 45.8587 68.5067 42.9588C70.2574 37.4727 69.2585 40.485 70.4687 37.0726C71.0855 35.3335 66.625 33.6057 64.5826 33.1485C62.1024 31.0912 58.8859 28.4179 57.7154 31.1865C56.2548 34.641 55.7533 36.0916 52.8103 43.8966M64.5826 49.8683L71.5223 54.0892C73.9733 56.8322 70.8633 56.6919 69.2585 56.6919C61.6721 53.0346 46.4119 46.1759 44.3111 44.5301C42.2102 42.8842 43.1591 42.4728 43.5968 41.787C44.0345 41.5584 46.7436 43.2108 50.8482 43.8966H52.8103"
                stroke="black"
                strokeWidth="1.96205"
              />
            </g>
            <path
              id="Vector 201"
              d="M60.2055 44.5641C57.6699 43.9934 54.5709 41.9481 53.8666 40.9968L52.8101 43.8507C53.8666 44.802 56.1908 46.1338 57.036 46.7046C57.8812 47.2753 64.4315 48.1315 64.4315 48.845L66.5444 45.2776C64.7836 45.5154 62.7411 45.1349 60.2055 44.5641Z"
              fill="#F01414"
            />
          </g>
          <g id="bowtie">
            <path
              d="M43.8862 81.8672L38 82.8482L39.9621 75L43.8862 81.8672C43.8862 80.7772 45.4067 79.3601 46.0935 79.3601C46.7551 79.3601 47.2042 80.6754 47.7479 81.7464L53.6964 77.9431L51.7344 87.7533L47.8103 81.8672C47.8103 82.9572 46.2406 85.6823 44.8672 84.8103C43.4937 83.9382 43.8862 82.2305 43.8862 81.8672Z"
              fill="#F01414"
            />
            <path
              d="M43.8862 81.8672L38 82.8482L39.9621 75L43.8862 81.8672ZM43.8862 81.8672C43.8862 82.2305 43.4937 83.9382 44.8672 84.8103C46.2406 85.6823 47.8103 82.9572 47.8103 81.8672M43.8862 81.8672C43.8862 80.7772 45.4067 79.3601 46.0935 79.3601C46.7802 79.3601 47.238 80.7772 47.8103 81.8672M46.7066 82.4122L53.6964 77.9431L51.7344 87.7533L47.8103 81.8672"
              stroke="black"
              strokeWidth="0.981026"
            />
          </g>
        </g>
      </svg>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="6
        "
        height="6
        "
        viewBox="0 0 4 4"
        fill="none"
        className={classes["eyeball-real"]}
        style={{
          transform: `rotate(${90 - eyeDeg}deg)`,
        }}
      >
        <path
          id="eyeballz"
          d="M4 2C4 3.10457 3.10457 4 2 4C0.89543 4 0 3.10457 0 2C0 0.89543 0.89543 0 2 0C3.10457 0 4 0.89543 4 2Z"
          fill="black"
        />
      </svg>
    </div>
  );
});

export default Birb3;
