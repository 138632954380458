import { useState, useRef, forwardRef, useImperativeHandle } from "react";

import classes from "./Birb1.module.css";

const Birb1 = forwardRef((props, ref) => {
  const eyeRef = useRef();
  const [eyeDeg, setEyeDeg] = useState(0);

  const { x: mouseX, y: mouseY } = props.mouseCoords;

  const angle = (cx, cy, ex, ey) => {
    const dy = ey - cy;
    const dx = ex - cx;
    const rad = Math.atan2(dy, dx);
    const deg = (rad * 180) / Math.PI;
    return deg;
  };

  useImperativeHandle(ref, () => ({
    angleCalc() {
      const rekt = eyeRef.current.getBoundingClientRect();
      const anchorX = rekt.left + rekt.width / 2;
      const anchorY = rekt.top + rekt.height / 2;
      const angleDeg = angle(mouseX, mouseY, anchorX, anchorY);

      // console.log(angleDeg);
      setEyeDeg(180 - angleDeg);
    },
  }));

  return (
    <div className={classes["birb-container"]}>
      <svg
        width="175"
        height="175"
        viewBox="0 0 175 175"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="birb1">
          <g id="beak-closed">
            <path
              d="M132.068 54.9655C134.396 53.8011 139.674 53.7944 144.618 53.7944C149.52 53.7944 140.269 59.9892 134.229 63.0863C134.151 63.4245 134.077 63.1643 134.077 63.1643L134.411 58.4792L132.068 54.9655Z"
              fill="#BB7E5D"
            />
            <path
              d="M144.618 53.7944C139.674 53.7944 134.396 53.8011 132.068 54.9655L134.411 58.4792M144.618 53.7944C149.562 53.7944 140.111 60.0945 134.077 63.1643M144.618 53.7944C142.275 54.9657 136.753 57.308 134.411 58.4792M134.077 63.1643C134.077 63.1643 134.411 64.3354 134.411 58.4792M134.077 63.1643L134.411 58.4792"
              stroke="black"
              strokeWidth="2"
            />
          </g>
          <g id="body">
            <path
              d="M95.7601 67.8492L98.1025 57.3081C98.1025 53.3599 111.008 46.767 116.842 46.767C122.139 46.767 130.897 54.9657 133.771 60.4824C134.411 61.993 133.771 64.8694 133.771 64.8694C132.823 68.0092 131.224 71.3544 129.964 73.9882C129.464 75.0344 129.018 75.9683 128.687 76.7326L130.319 87.6814C129.884 92.0214 124.399 109.617 121.527 114.698C119.575 117.822 112.384 124.021 96.4727 125.215C80.5341 126.411 68.1092 124.536 64.1369 124.068C63.3987 123.744 62.6079 123.366 61.7944 122.937C56.9872 120.404 51.3917 116.101 51.2243 110.562C44.2842 111.937 33.6184 112.386 29.153 112.438C29.9145 110.744 32.4165 105.515 36.3326 98.1475C38.4258 94.2097 42.3525 90.1296 46.3686 86.5054C46.0891 86.2261 45.7732 85.9296 45.437 85.614L45.4364 85.6135C43.2947 83.6033 40.3249 80.8158 40.6301 76.7326C40.9832 72.0079 37.9815 68.8176 37.9815 68.8176L29 45.5958L35.7744 48.6377L34.4501 30.4605L40.1887 36.793L38.3698 21C45.1743 30.4312 54.7202 49.8643 55.9131 53.902C56.3301 55.3134 57.3209 57.7514 58.4774 60.5972L58.4774 60.5974L58.4775 60.5974C60.6296 65.8931 63.3556 72.6008 64.0256 76.7326C67.5379 76.2091 70.3805 75.9577 72.2467 75.7926L72.2474 75.7926C73.1095 75.7163 73.7632 75.6585 74.1783 75.6008C75.2992 75.445 76.6624 75.4517 82.8766 72.5341C86.7944 70.6947 92.6474 69.2178 95.7601 67.8492Z"
              fill="#C7D0D7"
            />
            <path
              d="M51.2243 110.562C51.3917 116.101 56.9872 120.404 61.7944 122.937C62.6079 123.366 63.3987 123.744 64.1369 124.068C68.1092 124.536 80.5341 126.411 96.4727 125.215C112.384 124.021 119.575 117.822 121.527 114.698C124.399 109.617 129.884 92.0214 130.319 87.6814L128.687 76.7326C129.018 75.9683 129.464 75.0344 129.964 73.9882C131.224 71.3544 132.823 68.0092 133.771 64.8694C133.771 64.8694 134.411 61.993 133.771 60.4824C130.897 54.9657 122.139 46.767 116.842 46.767C111.008 46.767 98.1025 53.3599 98.1025 57.3081L95.7601 67.8492C92.6474 69.2178 86.7944 70.6947 82.8766 72.5341C76.6624 75.4517 75.2992 75.445 74.1783 75.6008C73.7631 75.6585 73.1091 75.7164 72.2467 75.7926C70.3805 75.9577 67.5379 76.2091 64.0256 76.7326M51.2243 110.562C44.2842 111.937 33.6184 112.386 29.153 112.438C29.9145 110.744 32.4165 105.515 36.3326 98.1475M51.2243 110.562C64.5079 108.578 91.3094 102.178 92.2464 92.445M36.3326 98.1475C38.4258 94.2097 42.3525 90.1296 46.3686 86.5054M36.3326 98.1475C39.7617 91.6964 48.1117 84.8634 53.7142 80.2787M46.3686 86.5054C46.0889 86.2259 45.7729 85.9293 45.4364 85.6135C43.2947 83.6033 40.3249 80.8158 40.6301 76.7326C40.9832 72.0079 37.9815 68.8176 37.9815 68.8176L29 45.5958L35.7744 48.6377L34.4501 30.4605L40.1886 36.793L38.3698 21C45.1743 30.4312 54.7202 49.8643 55.9131 53.902C56.3301 55.3134 57.3209 57.7515 58.4774 60.5973C60.6296 65.893 63.3556 72.6007 64.0256 76.7326M46.3686 86.5054C48.9318 84.1923 51.5315 82.0649 53.7142 80.2787M64.0256 76.7326C63.0917 76.8717 61.4633 77.2635 59.8993 77.6398C58.1008 78.0726 56.3873 78.4848 55.9131 78.4689C55.2502 79.0218 54.5099 79.6276 53.7142 80.2787"
              stroke="black"
              strokeWidth="2"
            />
          </g>
          <circle
            id="eye"
            cx="114.5"
            cy="59.6506"
            r="7.19861"
            fill="white"
            stroke="black"
            strokeWidth="2"
            ref={eyeRef}
          />
          <path
            id="left-foot"
            d="M61.7946 122.897L59.4521 128.753L68.6996 137.197M68.6996 137.197L67.6508 139.294L65.3083 142.808L68.822 139.294L73.5069 141.637V139.294L81.7055 142.808L68.6996 137.197Z"
            stroke="black"
            strokeWidth="2"
          />
          <path
            id="right-foot"
            d="M78.1918 125.239L73.5068 131.096L85.2191 136.952M85.2191 136.952V139.294V140.465L86.3904 139.294L91.0753 140.465V139.294L95.7602 140.465L85.2191 136.952Z"
            stroke="black"
            strokeWidth="2"
          />
          <path
            id="eyeball"
            d="M120.356 58.4794C120.356 61.0668 118.259 63.1643 115.671 63.1643C113.084 63.1643 110.986 61.0668 110.986 58.4794C110.986 55.8919 113.084 53.7944 115.671 53.7944C118.259 53.7944 120.356 55.8919 120.356 58.4794Z"
            fill="black"
            className={classes["eyeball-fake"]}
          />
        </g>
      </svg>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="8"
        height="8"
        viewBox="0 0 4 4"
        fill="none"
        className={classes["eyeball-real"]}
        style={{
          transform: `rotate(${90 - eyeDeg}deg)`,
        }}
      >
        <path
          id="eyeballz"
          d="M4 2C4 3.10457 3.10457 4 2 4C0.89543 4 0 3.10457 0 2C0 0.89543 0.89543 0 2 0C3.10457 0 4 0.89543 4 2Z"
          fill="black"
        />
      </svg>
    </div>
  );
});

export default Birb1;
